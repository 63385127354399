.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 0 20px gray , inset 0 0 2px gray;
  color: #9B38DC;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2222200000;
  box-sizing: border-box;
}
.logoutButton1,
.logoutButton {
  padding: 0.5rem 1rem;
  background-color: rgba(255, 77, 77, 0);
  color: gray;
  border: 1px solid gray;
  border-radius: 200px;
  cursor: pointer;
}
.logoutButton1{
  background-color: #7b2eb0;
  color: white;
  border: 1px solid #7b2eb0;
  padding: 0.2rem 0.4rem;
}

.logoutButton1:hover {
  color: #7b2eb0;
  background-color: white;
}
.logoutButton:hover {
  border: 1px solid #9B38DC;
  color: white;
  background-color: #9B38DC;

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
}

.modalContent {
    position: absolute;
    top: 100px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.modalContent::-webkit-scrollbar {
    width: 8px;
}

.modalContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
    background: #9B38DC;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
    background: #7100b3;
}

.modalContent h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.formGroup {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formGroup label {
    width: 250px;
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: 500;
    text-align: start;
}

.formGroup input[type="text"],
.formGroup input[type="email"] {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.selectUsers {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 100px;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    gap: 10px;
}

.saveButton {
    background-color: #9B38DC;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.cancelButton {
    background-color: #ececec;
    color: #9B38DC;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.saveButton:hover,
.cancelButton:hover {
    opacity: 0.8;
}

.selectRoles {
    width: 100%;
}

.selectedKeysContainer {
    margin-top: -20px;
    width: 100%;
    margin-left: 0;
    padding-left: 185px;
}

.selectedKeys {
    padding: 15px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;
    width: 100%;
}

.selectedKey {
    display: inline-flex;
    align-items: center;
    background: #F8F8F8;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 8px 12px 8px 16px;
    font-size: 14px;
    color: #333333;
    margin: 0;
    gap: 12px;
    transition: all 0.3s ease;
}

.removeKeyButton {
    background: none;
    border: none;
    color: #9B38DC;
    font-size: 18px;
    cursor: pointer;
    padding: 0 2px;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 0;
}

.checkboxLabel {
    font-size: 14px;
    color: #333;
}

.customCheckbox {
    position: relative;
    width: 50px;
    height: 24px;
}

.customCheckbox input {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkboxSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.checkboxSlider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.customCheckbox input:checked + .checkboxSlider {
    background-color: #9B38DC;
}

.customCheckbox input:checked + .checkboxSlider:before {
    transform: translateX(26px);
}

:global(body.modal-open) {
    overflow: hidden;
}

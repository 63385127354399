.nav{
    padding-left: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    gap :30px;

} .nav> li a{
    color: gray;
    transition: color 0.3s;
    position: relative;
    padding: 30px 0;
    text-decoration: none;
}

.nav > li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #9B38DC;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.nav > li a:hover,
.nav > li a.active {
    color: #9B38DC;
}

.nav > li a:hover::after,
.nav > li a.active::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.container {
    display: flex;
   min-height: 100vh;
    /*overflow: scroll;*/
}

.sidebar {
    min-width: 200px;
    max-width: 200px;
    /*background-color: #f0f0f0;*/
    padding: 20px;
    border-left: 1px solid gray ;
}

.sidebarTitle {
    font-size: 24px;
    margin-bottom: 20px;
}

.sidebarMenu {
    list-style-type: none;
    padding: 0;
}

.sidebarMenuItem {
    padding: 10px;
    cursor: pointer;
}

.sidebarMenuItem.active {
    background-color: #e0e0e0;
    font-weight: bold;
}

.content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    /*background-color: #ffffff;*/
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    border-radius: 5px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.createButton {
    display: flex;
    align-items: center;
    background-color: #9B38DC;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.createButton:hover {
    opacity: 0.5;
}

.plusIcon {
    font-size: 18px;
    margin-right: 8px;
}

.buttonText {
    font-weight: bold;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.searchContainer {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.searchIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
}

.searchInput {
    width: 100%;
    padding: 10px 10px 10px 40px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.showKeysButton, .editButton {
    background-color: #f5f5f5;
    border-radius: 5px;
    color: #9B38DC;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}
.showKeysButton:hover, .editButton:hover{
    /*box-shadow: inset 0 0 10px #9B38DC ;*/
    opacity: 0.5;
}
.keysPopup {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1000;
    max-height: 300px;
    width: 400px;
    display: flex;
    flex-direction: column;
}

.keysPopupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #ddd;
}

.keysPopupList {
    list-style-type: none;
    padding: 10px;
    margin: 0;
    overflow-y: auto;
    flex-grow: 1;
}

.keysPopupItem {
    padding: 5px 0;
}

/* Стли для скроллбара */
.keysPopupList::-webkit-scrollbar {
    width: 8px;
}

.keysPopupList::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.keysPopupList::-webkit-scrollbar-thumb {
    background: #9B38DC;
    border-radius: 5px;
}

.keysPopupList::-webkit-scrollbar-thumb:hover {
    background: #7c2eb3;
}

.closePopupButton {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
    padding-top: 100px;
    overflow-y: auto;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    margin: 0 20px;
    margin-bottom: 50px;
}

.editCategoryKeyForm {
    display: block;
}

.availableKeysWrapper {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
}

.categoryKeySearchInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.keysList {
    display: block;
}

.keyItem {
    display: block;
    padding: 8px 12px;
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: background-color 0.2s;
}

.keyItem:hover {
    background-color: #e8e8e8;
}

.addRemoveKeyButton {
    float: right;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;
}

.addRemoveKeyButton:hover {
    transform: scale(1.1);
    opacity: 0.5;
}

.addRemoveKeyButton:focus {
    outline: none;
}

.keyItem .addRemoveKeyButton {
    background-color: #4CAF50;
    color: white;
}

.selectedKeyItem .addRemoveKeyButton {
    background-color: #f44336;
    color: white;
}

.modalButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    text-align: right;
}

.modalButtons button {
    margin-left: 10px;
}

.error {
    color: red;
    margin-bottom: 10px;
}

/* Добавим такие же стили для .availableKeysWrapper */
.availableKeysWrapper::-webkit-scrollbar {
    width: 8px;
}

.availableKeysWrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.availableKeysWrapper::-webkit-scrollbar-thumb {
    background: #9B38DC;
    border-radius: 5px;
}

.availableKeysWrapper::-webkit-scrollbar-thumb:hover {
    background: #7c2eb3;  /* Немного темнее при наведении */
}

.body{
    display: flex;
    justify-content: center;
    gap: 50px;
    padding-top: 10%;
}
.body button {
    padding: 15px 20px;
    background-color: #9B38DC;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.availableKeysWrapper::-webkit-scrollbar-thumb:hover {
    background: #7c2eb3;
}
.body button:hover {
    opacity: 0.5;
}
.save{
    background-color: #9B38DC;
    color: whitesmoke;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}
.otmena{
    background-color: #eeeeee;
    color: #9B38DC;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}
.otmena:hover{
    opacity: 0.5;
    cursor: pointer;
}
.save:hover{
    opacity: 0.5;
    cursor: pointer;
}
.editCategoryKeyForm input{
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    margin: 10px;
}
.inputGroup{
    padding:  10px;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
}
.inputGroup label{
    width: 300px;
    text-align: start;
}
.inputGroup input{
    border: 1px solid gray;
}
.readonly{
    background-color: #cccccc;
}

.showUsersButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.usersPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 50%;
    min-width: 300px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.usersPopupInner {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 80vh;
}

.usersPopupHeader {
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.usersPopupContent {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 20px;
}

.usersPopupFooter {
    padding: 20px;
    border-top: 1px solid #eee;
    background-color: white;
}

.usersPopupTable {
    width: 100%;
    border-collapse: collapse;
}

.usersPopupTable th,
.usersPopupTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.usersPopupTable th {
    background-color: #f2f2f2;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
}

.usersPopupTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.usersPopupTable tr:hover {
    background-color: #f5f5f5;
}

.usersPopupItem td:first-child {
    width: 50px;
    text-align: center;
    vertical-align: middle;
}

.closePopupButton {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.usersPopupList {
    list-style-type: none;
    padding: 0;
}

.usersPopupItem {
    padding: 5px 0;
}

.toggleUserButton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;
    background-color: #4CAF50;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.toggleUserButton.selectedUser {
    background-color: #f44336;
}

.toggleUserButton:hover {
    transform: scale(1.1);
}

.toggleUserButton:focus {
    outline: none;
}

.usersPopupFooter {
    margin-top: 20px;
    text-align: right;
}

.saveUsersButton {
    background-color: #9B38DC;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.saveUsersButton:hover {
    opacity: 0.5;
}
.usersPopupHeader{
    display: flex;
    justify-content: space-between;
}

.keysListContainer {
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.keysList {
    padding: 10px;
}
.keysPopupItem{
    border-bottom: 1px solid gray;
}

.keysList {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.keysList > div {
    margin-bottom: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.keysList > div:last-child {
    margin-bottom: 0;
}

.delete {
    background-color: #ff4136;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.delete:hover {
    opacity: 0.5;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.loadingSpinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #9B38DC;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.selectedUsers {
    margin-left: 252px;
    box-shadow: 0  0 8px;
    margin-right: 20px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    border: 1px solid gray;
}

.userTag {
    display: inline-flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 5px;
    font-size: 14px;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.userTag button {
    background: none;
    border: none;
    color: #ff1744;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.userTag button:hover {
    color: #d50000;
}

.userSelectContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.userSelectContainer .inputGroup {
    flex: 1;
}

.selectUsers {
    width: 100%;
    /*border: 1px solid gray;*/
    padding: 0;
    border-radius: 8px;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.flexItem {
    flex: 1;
    min-width: 0;
}
.selectedKeys{
    border: 1px solid gray;
    padding: 10px;
    width: 100%;
    margin: 0 8px;
    border-radius: 8px;
    min-height:40px;
    text-align: start;
}

/* Стили для скроллбара */
.modalContent::-webkit-scrollbar {
    width: 8px;
}

.modalContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
    background: #9B38DC;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
    background: #7b2eb0;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    list-style: none;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #9B38DC;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #7c2db3;
}

a{
    text-decoration: none;
}
.body{
    padding: 30px ;
}
button{
    transition: 1s;
}
body h2{
    color: #9B38DC;
}
.MasageModal,
.createButton {
    display: flex;
    align-items: center;
    background-color: #9B38DC;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 10px;
    transition: background-color 0.3s;
}

.createButton1 {
    display: flex;
    align-items: center;
    background-color: rgba(238, 238, 238, 0.76);
    color:  #9B38DC;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}
.createButton1:hover,
.createButton:hover{
    opacity: 0.5;
}
.Routes{
    margin-top: 100px;
}
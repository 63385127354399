.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.formGroup {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.formGroup label {
    width: 250px;
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: 500;
    text-align: start;
}

.inputWrapper {
    position: relative;
    width: 100%;
}

.formGroup input[type="text"],
.formGroup input[type="email"],
.formGroup input[type="password"] {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.formGroup input:active {
    border: 1px solid #dddddd;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.buttonGroup button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
    margin-left: 10px;
}

.save {
    background-color: #9B38DC;
    color: whitesmoke;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.save:hover {
    opacity: 0.5;
}

.cancel {
    background-color: #ececec;
    color: #9B38DC;
}

.cancel:hover {
    opacity: 0.5;
}

.selectRoles {
    width: 100%;
}

.selectedKeysContainer {
    margin-top: 0;
    width: 100%;
    margin-left: 0;
    padding-left: 0;
}

.selectedKeysContainer h4 {
    margin: 0 0 12px 0;
    font-size: 14px;
    color: #666;
    font-weight: 500;
}

.selectedKeys {
    padding: 15px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;
    width: 100%
}

.selectedKey {
    display: inline-flex;
    align-items: center;
    background: #F8F8F8;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 8px 12px 8px 16px;
    font-size: 14px;
    color: #333333;
    margin: 0;
    gap: 12px;
    transition: all 0.3s ease;
}

.selectedKey:hover {
    background: rgba(155, 56, 220, 0.1);
    border-color: #9B38DC;
}

.removeKeyButton {
    background: none;
    border: none;
    color: #9B38DC;
    font-size: 18px;
    cursor: pointer;
    padding: 0 2px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.removeKeyButton:hover {
    opacity: 0.5;
}

.keyLabel {
    font-size: 14px;
    color: #333;
    font-weight: 500;
}

/* Стили для Select компонента */
.selectRoles .select__control {
    min-height: 38px;
    border-color: #ccc;
    border-radius: 5px;
}

.selectRoles .select__control:hover {
    border-color: #9B38DC;
}

.selectRoles .select__control--is-focused {
    border-color: #9B38DC;
    box-shadow: 0 0 0 1px #9B38DC;
}

.selectRoles .select__menu {
    background-color: white;
}

.selectRoles .select__option {
    background-color: white;
    color: #333;
}

.selectRoles .select__option--is-focused {
    background-color: rgba(155, 56, 220, 0.1);
    color: #333;
}

.selectRoles .select__option--is-selected {
    background-color: #9B38DC;
    color: white;
}

.selectRoles .select__option:active {
    background-color: #7100b3;
    color: white;
}

.inputError {
    border-color: #ff4d4f !important;
}

.errorText {
    color: #ff4d4f;
    font-size: 12px;
    display: block;
    text-align: left;
    position: absolute;
    left: 190px;
    bottom: -20px;
}

.selectError .select__control {
    border-color: #ff4d4f !important;
}

.formGroup:has(#roles) .errorText {
    left: 10px;
}
.pageContainer {
    padding: 20px;
    /*background-color: #f5f7fa;*/
    min-height: 100vh;
}

.tableContainer {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.table th,
.table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}
.top_block{
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 20px 10px;
}
.top_block > button{
    margin-bottom: 20px;
    padding: 15px 20px;
    background-color: #a94fd9;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.top_block > button:hover{
    opacity: 0.5;
}
.table tbody tr:hover {
   background-color: rgb(244, 248, 248);
}

.editButton {
    background-color: #eeeeee;
    color: #9B38DC;
    border: none;
    padding:  12px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.editButton:hover {
    opacity: 0.5;
}

.deleteButton {
    background-color: #9B38DC;
    color: white;
    border: none;
    padding:  12px 20px;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.deleteButton:hover {
    opacity: 0.5;
}

.messageModal {
    position: fixed;
    top: 80px;
    right: -50px;
    transform: translate(-50%, -50%);
    background-color: #f87676;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1002342342340;
}

.messageModal button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #9B38DC;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.activ{
    color: #28a745
}
.foled{
    color: red;
}
.confirm_button2,
.confirm_button{
    background-color: #9B38DC;
    color: whitesmoke;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}
.confirm_button2{
    background-color: whitesmoke;
    color:#9B38DC;
}
.confirm_button2:hover,
.confirm_button:hover{
    opacity: 0.5;
}

.successModal {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 123456789000;
    animation: slideIn 0.3s ease-out;
}

.successModal button {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

.successModal button:hover {
    background-color: #45a049;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
.errorModal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #ff6b6b;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10666600;
  max-width: 300px;
}

.errorContent {
  position: relative;
}

.closeButton {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  font-size: 20px;
}

.errorContent p {
  margin: 0;
  font-size: 14px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10666600;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.modalContent > p{
  margin: 10px 0;
}
.buttons{
  display: flex;
  justify-content: center;
}
.modalContent h2 {
  margin-top: 0;
}

.modalContent .buttons button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #7100b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modalContent .buttons button:hover {
  opacity: 0.5;
}

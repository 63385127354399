.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
}

.modalContent {
    position: absolute;
    top: 100px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.modalContent::-webkit-scrollbar {
    width: 8px;
}

.modalContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
    background: #9B38DC;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
    background: #7100b3;
}

.modalContent h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.modalContent form {
    display: flex;
    flex-direction: column;
}

.modalContent input,
.modalContent select {
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modalContent button,
.modalContent button[type="button"] {
    background-color: rgba(241, 240, 240, 0.99);
    color: #9B38DC;
    margin-left: 10px;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.modalContent button:hover {
    opacity: 0.5;
}

.save {
    background-color: #9B38DC;
    color: whitesmoke;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.modalContent .cansle {
    background-color: rgba(250, 250, 250, 0.91);
    color: #9B38DC;
    border: none;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

:global(body.modal-open) {
    overflow: hidden;
}
.authContainer {
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.block{
  display: flex;
  justify-content: center;
}
.authContainer h2 {
  color: #9B38DC;
  margin: 5px 0 20px;
  text-align: center;
}

.form {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
}

.input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  width: 100%;
  padding:10px 20px;
  background-color: #9B38DC;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  opacity: 0.5;
}

.switchText {
  text-align: center;
  margin-top: 1rem;
}

.switchButton {
  background: none;
  border: none;
  color: #9B38DC;
  cursor: pointer;
  text-decoration: underline;
}

.switchButton:hover {
  opacity: 0.5;
}

.qr_block {
  padding-bottom: 30px;
}

.twoFactor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.inputGroup {
  position: relative;
  margin-bottom: 20px;
}
.inputError {
  border-color: #ff0000;
}

.errorText {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
  bottom: -7px;
  left: 0;
}
.text{
  text-align: center;
  margin: 20px 0;
  line-height: 1.5;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
    padding-top: 100px;
    overflow-y: auto;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    margin: 0 20px;
    margin-bottom: 50px;
}

.modalContent::-webkit-scrollbar {
    width: 8px;
}

.modalContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
    background: #9B38DC;
    border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
    background: #7b2eb0;
}
